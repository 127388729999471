*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;

}

.Search-section label{
  color:#fff;
  font-weight:500;
}
.theme-fontcolor{
  color: #ff0049!important;
}
.main_heading_style{
  min-height: 1rem;
  font-size: 4rem;
  font-weight: bold;
  color: white;
}
.main_heading_span{
 font-size: 20px!important;
 color: black!important;
 line-height: 4.2!important;
 font-weight: 700;
 font-family: 'Roboto',sans-serif;
}
.find-lts-jobs{
  /* background-color: #fff; */
  /* background-image: url('../public/matches_bg.jpg'); */
}
.membership-plan .plan-heading{
  /* width: 100%; */
	text-align: center;
	/* margin-top: 90px; */
}

/* Desired List */
.desired_percent span{
  font-size: 15px;
    font-weight: 600;
    background: #fafafa;
    /* color: #757575; */
    display: inline-block;
    border: 1px solid #e5e5e5;
    padding: 0px 15px;
    line-height: 2;
    margin-right: 8px;
    border-radius: 20px;
    margin-top: 5px;
}
.desired_percent .green{
 background-color: 	#008000;
 color:white;
}
.desired_percent .pink{
  color:white;
  background-color: 	#ee0a4b;
 }
 .desired_percent .yellow{
  background-color: 	#ffd700;
 }

 .job-item .img-profile img{
  transition: transform 0.3s linear;
 }

 .job-item:hover .img-profile img{
  transform: scale(1.2)!important;
 }

 .img-profile{
   overflow: hidden;
 }

.wrapper{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 15px;
  margin: 50px;
  padding: 0px 20px;

}
.pricing-table{
 box-shadow: 0px 0px 18px #ccc;
 text-align: center;
 padding: 30px 0px;
 border-radius: 5px;
 position: relative;

}
.pricing-table .head {
  border-bottom:1px solid #eee;
  padding-bottom: 50px;
  transition: all 0.5s ease;
}
.pricing-table:hover .head{
 border-bottom:1px solid #ff0049;
 
}

.pricing-table .head .title{
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
}

.pricing-table .content .price{
  background:linear-gradient(to right, #ff0049 0%, #680622 100%);
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  line-height: 90px;
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0px 0px 10px #ccc;
  margin-top: -50px;
   transition: all 0.5s ease;
}
.pricing-table:hover .content .price{
  transform: scale(1.2);

}
.pricing-table .content .price h1{
  color:#fff;
  font-size: 30px;
  font-weight: 700;
}
.pricing-table .content ul, .membershipDetails ul{
 list-style-type: none;
 margin-bottom: 20px;
 padding-top: 10px;
}

.pricing-table .content ul li{
  margin: 20px 0px;
  font-size: 16px;
  color: #3f2d2d;
  font-weight: 600;
}

.membershipDetails ul li{
  margin: 10px 0px;
  font-size: 16px;
  color:rgb(3, 3, 3);
}

.pricing-table .content .sign-up{
  background:linear-gradient(to right, #ff0049 0%, #680622 100%);
  border-radius: 40px;
  font-weight: 500;
  position: relative;
  display: inline-block;
}


.pricing-table .btn {
color: #fff;
padding: 10px 32px;
display: inline-block;
text-align: center;
font-weight: 600;
-webkit-transition: all 0.3s linear;
-moz-transition: all 0.3 linear;
transition: all 0.3 linear;
border: none;
font-size: 19px;
text-transform: capitalize;
position: relative;
text-decoration: none;
  margin: 2px;
  z-index: 9999;
  text-decoration: none;
  border-radius:50px;

}

.pricing-table .btn:hover{
box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
}

.pricing-table .btn.bordered {
z-index: 50;
color: #333;
}
.pricing-table:hover .btn.bordered{
color:#fff !important;
}

.pricing-table .btn.bordered:after {
background: #fff none repeat scroll 0 0;
border-radius: 50px;
content: "";
height: 100%;
left: 0;
position: absolute;
top: 0;
-webkit-transition: all 0.3s linear;
-moz-transition: all 0.3 linear;
transition: all 0.3 linear;
width: 100%;
z-index: -1;	
-webkit-transform:scale(1);
-moz-transform:scale(1);
transform:scale(1);
}
.pricing-table:hover .btn.bordered:after{
opacity:0;
transform:scale(0);
}
.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}
.edit-icon:hover{
  color: #ff0049;
}

#registration_header li:hover{
  color: #ff0049;
}

@media screen and (max-width:768px){
 .wrapper{
      grid-template-columns: repeat(2,1fr);
  } 
}

@media screen and (max-width:600px){
 .wrapper{
      grid-template-columns: 1fr;
  } 
}

/* Profile Detail Section */
/* body {
  background: #1a1a1a;
  font-family: sans-serif;
  text-align: center;
  color: #fefefe;
  overflow-y: scroll;
} */

.ProfileDetails a {
  text-decoration: none;
}
.ProfileDetails a.link {
  color: #ee0a4b;
  -webkit-transition: all 150ms ease 0s;
  transition: all 150ms ease 0s;
}
.ProfileDetails a.link:hover {
  color: #e08f24;
}
.ProfileDetails p {
  margin-bottom: 10px;
}
.ProfileDetails header {
  margin: 60px 0 40px;
}
.ProfileDetails header h1 {
  margin-bottom: 0px;
}
.ProfileDetails header .sup {
  font-style: italic;
  color: #999;
  margin-top: 4px;
}
.ProfileDetails .tabs {
  position: relative;
  margin: 40px auto;
  width: 1024px;
  max-width: 100%;
  overflow: hidden;
  padding-top: 10px;
  margin-bottom: 60px;
}
.ProfileDetails .tabs input {
  position: absolute;
  z-index: 1000;
  width: 25%;
  height: 50px;
  left: 0;
  top: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  cursor: pointer;
  margin: 0;
}
.ProfileDetails .tabs input#tab-2 {
  left: 25%;
}
.ProfileDetails .tabs input#tab-3 {
  left: 50%;
}
.ProfileDetails .tabs input#tab-4 {
  left: 75%;
}
.ProfileDetails .tabs label{
  background: #ee0a4b;
  color: #fefefe;
  font-size: 15px;
  line-height: 50px;
  height: 60px;
  position: relative;
  top: 0;
  padding: 0 20px;
  float: left;
  display: block;
  width: 25%;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.1), -2px 0 2px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  -webkit-transition: all 150ms ease 0s;
  transition: all 150ms ease 0s;
}
.ProfileDetails .tabs label:hover {
  cursor: pointer;
}
.ProfileDetails .tabs label:after {
  content: '';
  background: #fefefe;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  display: block;
}
.ProfileDetails .tabs input:hover + label {
  background: #ff3d74;
}
.ProfileDetails .tabs label:first-of-type {
  z-index: 4;
}
.ProfileDetails .tab-label-2 {
  z-index: 4;
}
.ProfileDetails .tab-label-3 {
  z-index: 3;
}
.ProfileDetails .tab-label-4 {
  z-index: 2;
}
.ProfileDetails .tabs input:checked + label {
  background: #fefefe;
  color: #1a1a1a;
  z-index: 6;
}
.ProfileDetails .clear-shadow {
  clear: both;
}
.ProfileDetails .content {
  height: auto;
  width: 100%;
  float: left;
  position: relative;
  z-index: 5;
  background: #fefefe;
  top: -10px;
  box-sizing: border-box;
}
.ProfileDetails .content div {
  position: relative;
  float: left;
  width: 0;
  height: 0;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  background: #fefefe;
}
.ProfileDetails .content div h2 {
  margin-top: 0;
}
.ProfileDetails .tabs .tab-selector-1:checked ~ .content .content-1, .ProfileDetails .tabs .tab-selector-2:checked ~ .content .content-2, .ProfileDetails .tabs .tab-selector-3:checked ~ .content .content-3, .ProfileDetails .tabs .tab-selector-4:checked ~ .content .content-4 {
  z-index: 100;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
  width: 100%;
  height: auto;
  width: 100%;
  height: auto;
  padding: 5%;
}
.ProfileDetails .content div h2 {
  color: #ee0a4b;
}
.ProfileDetails .content div p {
  font-size: 14px;
  line-height: 22px;
  font-style: italic;
  text-align: left;
  margin: 0;
  color: #777;
  padding-left: 15px;
  border-left: 8px solid rgba(63, 148, 148, 0.1);
}
/* Added */
.ProfileDetails .tabs li {
  background: #6b6363;
  color: #fefefe;
  font-size: 15px;
  line-height: 50px;
  height: 60px;
  position: relative;
  top: 0;
  float: left;
  display: block;
  width: 16.6%;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.1), -2px 0 2px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  -webkit-transition: all 150ms ease 0s;
  transition: all 150ms ease 0s;
}
@media screen and (min-width: 768px) {
  .ProfileDetails .tabs input:checked + label {
    top: -5px;
 }
 /* Added */
 .ProfileDetails .tabs .active {
   background-color: white;
  /* top: -5px; */
}
}
@media screen and (max-width: 767px) {
  .ProfileDetails .tabs {
    height: auto;
    background: #fefefe;
    padding-bottom: 200px;
    width: 95%;
 }
  .ProfileDetails .tabs label {
    text-align: left;
 }
  .ProfileDetails .tabs input:checked + label {
    padding-left: 30px;
 }
  .ProfileDetails .tabs label, .ProfileDetails .tabs input {
    width: 100%;
    position: absolute;
 }
  .ProfileDetails .tabs label.tab-label-1, .ProfileDetails .tabs input.tab-label-1, .ProfileDetails .tabs label#tab-1, .ProfileDetails .tabs input#tab-1 {
    width: 88%;
    top: 150px;
    left: 0;
    height: 50px;
    z-index: 4 !important;
 }
  .ProfileDetails .tabs label.tab-label-2, .ProfileDetails .tabs input.tab-label-2, .ProfileDetails .tabs label#tab-2, .ProfileDetails .tabs input#tab-2 {
    width: 92%;
    top: 100px;
    left: 0;
    height: 100px;
    z-index: 3 !important;
 }
  .ProfileDetails .tabs label.tab-label-3, .ProfileDetails .tabs input.tab-label-3, .ProfileDetails .tabs label#tab-3, .ProfileDetails .tabs input#tab-3 {
    width: 96%;
    top: 50px;
    left: 0;
    height: 150px;
    z-index: 2 !important;
 }
  .ProfileDetails .tabs label.tab-label-4, .ProfileDetails .tabs input.tab-label-4, .ProfileDetails .tabs label#tab-4, .ProfileDetails .tabs input#tab-4 {
    width: 100%;
    top: 0;
    left: 0;
    height: 200px;
    z-index: 1 !important;
 }
  .ProfileDetails .tabs .content {
    top: 200px;
 }
}


/* Desired Card Section */
.desired_section header {
  position: fixed;
  left: 0;
  right:0;
  margin:auto;
  top: .5rem;
  text-align: center;
  color: #222;
}
.desired_section .card{
  max-width: 350px;
  width: 100%;
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
}
.desired_section .card .header{
  display: flex;
  align-items: center;
}
.desired_section .header .img{
  height: 75px;
  width: 75px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.desired_section .header .details{
  margin-left: 20px;
}
.desired_section .details span{
  display: block;
  background: #d9d9d9;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.desired_section .details .name{
  height: 15px;
  width: 100px;
}
.desired_section .details .about{
  height: 13px;
  width: 150px;
  margin-top: 10px;
}
.desired_section .card .description{
  margin: 25px 0;
}
.desired_section .description .line{
  background: #d9d9d9;
  border-radius: 10px;
  height: 13px;
  margin: 10px 0;
  overflow: hidden;
  position: relative;
}
.desired_section .description .line-1{
  width: calc(100% - 15%);
}
.desired_section .description .line-3{
  width: calc(100% - 40%);
}
.desired_section .card .btns{
  display: flex;
}
.desired_section .card .btns .btn{
  height: 45px;
  width: 100%;
  background: #d9d9d9;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
}
.desired_section .btns .btn-1{
  margin-right: 8px;
}
.desired_section .btns .btn-2{
  margin-left: 8px;
}
.desired_section .header .img::before,
.desired_section .details span::before,
.desired_section .description .line::before,
.desired_section .btns .btn::before{
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, #d9d9d9 0%, rgba(0,0,0,0.05) 20%, #d9d9d9 40%, #d9d9d9 100%);
  background-repeat: no-repeat;
  background-size: 450px 400px;
  animation: shimmer 1s linear infinite;
}
.desired_section .header .img::before{
  background-size: 650px 600px;
}
.desired_section .details span::before{
  animation-delay: 0.2s;
}
.desired_section .btns .btn-2::before{
  animation-delay: 0.22s;
}
@keyframes shimmer {
  0%{
    background-position: -450px 0;
  }
  100%{
    background-position: 450px 0;
  }
}

.desired_section{
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1100px) {
  .desired_section{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  }
}


/* Profile Admin Sidebar Loader */
.admin-profile-loader{
  position: relative;
}
.admin-profile-loader .container{
  /* position: absolute; */
  /* top: 40%;
  left: 40%; */
  /* display: block; */
  /* position: relative; */
}
.admin-profile-loader .loader{
  width: 50px;
  height: 50px;
  position: absolute;
  top: 30%;
  left: 25%;
  /* position: relative; */
}
.admin-profile-loader .loader::before,
.admin-profile-loader .loader::after {
  content: '';
  position: absolute;
  height: inherit;
  width: inherit;
  border-radius: 50%;
  /* mix-blend-mode: multiply; */
  animation: rotate 1s infinite cubic-bezier(0.77, 0, 0.175, 1);
}
.admin-profile-loader .loader::before {
  background-color: #fc3f9e;
}
.admin-profile-loader .loader::after {
  background-color: #50e8f3;
  animation-delay: .5s;
}
@keyframes rotate {
  0%,
  100% {
    left: 75px;
  }
  25% {
    transform: scale(.3);
  }
  50% {
    left: 0;
  }
  75% {
    transform: scale(1);
  }
}


/* Main-loader section */
.main_loader{
  height: 100vh;
    width: 100vw;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255 255 255 / 80%)!important;
    z-index: 5;
}
.main_loader .loading {
  display: flex;
}
.main_loader .loading .dot {
  position: relative;
  width: 2em;
  height: 2em;
  margin: 0.8em;
  border-radius: 50%;
}
.main_loader .loading .dot::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  animation: wave 2s ease-out infinite;
}
.main_loader .loading .dot:nth-child(1) {
  background: #7ef9ff;
}
.main_loader .loading .dot:nth-child(1)::before {
  animation-delay: 0.2s;
}
.main_loader .loading .dot:nth-child(2) {
  background: #89cff0;
}
.main_loader .loading .dot:nth-child(2)::before {
  animation-delay: 0.4s;
}
.main_loader .loading .dot:nth-child(3) {
  background: #4682b4;
}
.main_loader .loading .dot:nth-child(3)::before {
  animation-delay: 0.6s;
}
.main_loader .loading .dot:nth-child(4) {
  background: #0f52ba;
}
.main_loader .loading .dot:nth-child(4)::before {
  animation-delay: 0.8s;
}
.main_loader .loading .dot:nth-child(5) {
  background: #000080;
}
.main_loader .loading .dot:nth-child(5)::before {
  animation-delay: 1s;
}
@keyframes wave {
  50%, 75% {
    transform: scale(2.5);
 }
  80%, 100% {
    opacity: 0;
 }
}


.react-select__placeholder{
  color: #000!important;
  font-weight: 500!important;
}

.home-div{
  position:absolute;
  top:0px; 
  left:140px;
  min-width:450px;
  min-height:216px;
  max-height:216px;
  background-color: #fff;
  line-height:30px;
  /* border-left: 1px #6c757d solid; */
  box-shadow: 0 0 4px;
  
  overflow:scroll;
}

.home-browse-span{
  cursor: pointer;
  color:#000
}



